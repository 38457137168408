

$light: #f6fdfa;
$dark: #191f2e;
// $primary: #46d1a0;
$primary: #00a88c;
// $secondary: #8466c5;
// $secondary: #2469c7;
$secondary: #1f5aaf;

$serif: "adelle-condensed", serif;
$sans: "source-sans-pro", sans-serif;

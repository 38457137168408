@import 'settings';
@import 'queries';

.reset {
    padding:0;
    margin:0;
}

.sans {
    font-family:$sans;
    font-weight:400;
}

.header,
h1,
.serif {
    text-transform:uppercase;
    font-family:$serif;
    font-weight:200;
    letter-spacing:1px;
}

h1 img {
    vertical-align:middle;
    max-width:150px;
}

html,
body {
    @extend .reset;
}

body {
    @extend .sans;
    background:$light;
    font-size:1.25em;
}

.notice {
    text-align:center;
    border:1px solid $primary;
    color:$primary;
    font-weight:bold;
    padding:1em;
}

.btn.save {
    width:100%;
}

.list-btn {
    margin:0.5em;
}

a:link,
a:visited {
    color:$secondary;
}

.icon {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

#lang-alert{
    width:100%;
    background:black;
    color:#fff;
    text-align:center;
    box-shadow:0 0 3px 2px #000;
    font-size:0.8em;
    a {
        text-decoration:none;
        display:block;
        padding:0.15em;
        &:link, &:visited{
            color:#fff;
        }
        &:hover, &:focus {
            background:#fff;
            color:#000;
        }
        strong:before {
            @extend .icon;
            content:"\f071";
            padding-right:0.5em;
        }
    }
}

.header { text-align:center; }

.subhead {
    display:block;
    @extend .sans;
    font-weight:bold;
    text-transform:none;
    font-size:0.5em;
    margin-top:0.5em;
}

.subheadxx {
    font-size:0.35em;
    @extend .serif;
    max-width:350px;
    margin:2em auto 0 auto;
}

.masthead {

    .brand,
    .toggler
    {
        font-size:1.5em;
        display:inline-block;

        font-weight:bold;
        @include MQ(Splus) {
            font-size:1em;
        }
    }

    .toggler {
        margin-left:1em;
        @include MQ(Splus) {
            display:none;
        }
    }

    background:$primary;
    color:#fff;

    a,
    a:link,
    a:focus,
    a:hover,
    a:active {
        outline:0;
        text-decoration:none;
        color:#fff;
    }

    .toggler:focus,
    .brand a:focus {
        color:$secondary;
    }

    li {
        display:inline;
        a {
            display:block;
            padding:0.5em 0;
            border:1px solid lighten($primary, 10%);
            @include MQ(Splus) {
                display:inline-block;
                padding:0;
                border:0;
                border-bottom:2px solid transparent;
                margin-right:1em;
            }
        }

        &.active a {
            background:lighten($primary, 5%);
            @include MQ(Splus) {
                background:transparent;
                border-color:lighten($primary, 5%);
            }
        }

        a:hover,
        a:focus,
        a:active {
            color:$primary;
            background:#fff;
            @include MQ(Splus) {
                color:inherit;
                background:transparent;
            }
        }
    }

    @include MQ(Splus) {
        .brand a:hover,
        .brand a {border-bottom:2px solid transparent;}
        .brand a:focus {color:#fff;}
        a:hover,
        a:focus,
        li a:hover,
        li a:focus {
            border-color:#fff;
        }
    }

    nav {
        display:block;
        @include MQ(Splus) {
            display:inline-block;
            margin-left:1em;
        }
    }

    &[data-nav-visible="false"] #navigation {
        @include MQ(Sneg) {
            display: none;
        }
    }

    @include MQ(Mplus){
        width:50%;
        position:fixed;
        top:2em;
        left:0;
        background:transparent;
    }

    #select-lang span {
        display:none;
    }

    #lang-container ul {
        width:100%;
        @include MQ(Splus) {
            position:absolute;
            left:0;
            padding:0.25em 0;
            text-align:center;
            background-color:$primary;
        }

        @include MQ(Mplus){
            background-color:transparent;
        }
    }

    #select-lang:before {
        @extend .icon;
        content: '\f1ab';
        font-size:1.6em;
        vertical-align:middle;
    }

    #lang-container[data-nav-visible="false"] ul{
        display:none !important;
    }

    #lang-container[data-nav-visible="true"] ul{
        display:block !important;
    }
}


.menu,
.step-list,
.checks {
    @extend .reset;
    list-style-type:none;
}

.step-list {
    a {
        text-decoration:none;
        &:hover,
        &:active,
        &:focus {
            text-decoration:underline;
        }
    }
}

.homehead{
    background:$dark url(/img/anim.gif) no-repeat bottom;
}

.pagehead {
    background-size:cover;
    color: #fff;
    padding:2em 0;
    text-align:center;
    box-shadow: inset 0 225px 325px -125px black;

    h1 {
        margin:0;
        line-height:1.1;
        text-shadow:0 0 0.25em rgba(0,0,0,0.85);
    }

    @include MQ(Mplus) {
        position:fixed;
        top:0; left:0;
        width:50%;
        height:100%;
        z-index:-1;
    }
}

.about-page header.pagehead {
    box-shadow:none;
    h1 {
        text-shadow:none;
    }
}

.pagehead>div {
    @include MQ(Mplus){
        font-size:1.5em;
        width:15em;
        margin:0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-60%);
    }

    @include MQ(Lplus) {
        font-size:2em;
    }
}

@media only screen and (min-width: 992px) and (max-height: 850px) {
  .pagehead {
    font-size: 0.6em;
    padding-top: 8em;
  }
}
@media only screen and (min-width: 1450px) and (max-height: 850px) {
  .pagehead {
    font-size: 0.8em;
    padding-top: 7em;
  }
}

.site-footer,
main {
    display:block;
    @include MQ(Mplus) {
        margin-left:50%;
        padding:1em 0 0 0;
    }
}

.site-footer {
    padding:2em 0;
    padding-top:4em;
    border-top:3px solid $primary;
    background:#fff;

    .content {
        max-width:90%;
        margin:0 auto;

        small {
            display:block;
            font-size:0.75em;
        }

        .byline {
            a {
                color:inherit;
                &:hover,
                &:active,
                &:focus {
                    text-decoration:none;
                }
            }
            text-align:center;
            div {
                @extend .serif;
                margin-bottom:1em;
                font-size:80%;
                * {
                    margin-right:1em;
                }
            }
            img {
                display:inline-block;
                width:25%;
                margin-right:2em;
                vertical-align:middle;
            }
        }

        .logos {
            ul {
                list-style-type:none;
                li {
                    display:inline;
                }
            }
            text-align:center;
            padding:1em 0;
            img {
                display:inline-block;
            }
        }

        .disclaim {
            text-align:center;
            max-width:575px;
            margin:0 auto;
        }
    }
}




.btn {
    font-weight:bold;
    color:$secondary;
    background:transparent;
    display:inline-block;
    border:1px solid;
    border-radius:5px;
    text-decoration:none;
    padding:0.15em 2em;
}

.btn-focus {
}

.btn-hover {
    outline:0;
    background-color:$secondary;
    color:#fff;
    border-color:transparent;
}

.btn:hover {
    @extend .btn-hover;
}


.plans {

    .plan {

        padding:2.5em 1em;

        &:first-child {
            margin-top:-1em;
        }

        .plan-content {
            max-width:75%;
            margin:0 auto;
        }

        h1 {
            margin:0; padding:0;
            line-height:1;
            color:$primary;
            font-size:2em;
        }

        .plan-desc {
            margin:1em 0;
        }

        a {
            @extend .btn;
        }
    }
    .plan:hover,
    .plan.focused {
        background:$primary;
        color:#fff;
        h1 {
            color:#fff;
        }
    }
}

.plan .btn {
    cursor:pointer;
}

input.has-focus + .btn,
.plan:hover .btn,
.btn:hover,
.btn:active,
.btn:focus {
    @extend .btn-hover;
}

.planhead {
    font-size:0.65em;
    background:$primary;
    text-align:left;

    small {
        font-size:0.7em;
    }
}

[dir="rtl"] .planhead {
    text-align:right;
}

header.head-family {
    background:$primary url(/img/plan-bg/family.jpg) no-repeat center center;
    background-size:cover;
}

header.head-biz {
    background:$primary url(/img/plan-bg/business.jpg) no-repeat top right;
    background-size:cover;
}

header.head-faith {
    background:$primary url(/img/plan-bg/faith.jpg) no-repeat top right;
    background-size:cover;
}

.resources-page header {
    background:$primary url(/img/plan-bg/resources.jpg) no-repeat top right;
    background-size:cover;
}

.planhead>div {
    padding:1em;
    margin-top:1em;
    position:relative;
    z-index:90100;
    @include MQ(Mplus) {
        max-width:70%;
        margin:0 auto;
    }
}

.alerts-sect {
    padding-left:55px;
}

#local-signup {
    img {
        float:left;
        border-radius: 50%;
    }

    ul {
        list-style-type:none;
        margin:0.5em 0 1em 0;
        padding:0;
        li {
            margin-bottom:0.125em;
        }
    }
}

[dir="rtl"] #local-signup {
    img {
        float:right;
        margin-left:10px;
    }

    ul {
        margin:0.5em 3em 0 0;
    }
}


h2 strong{
    @extend .serif;
    display:block;
    font-size:1.5em;
    color:$primary;
}

.priv {
    font-style:italic;
    strong {
        color:$secondary;
    }
}

#start {
    margin-top:6em;
}

h3 {
    font-size:1.25em;
}

.resources-page {
    .planner h4 {
        @extend .serif;
        font-weight:bold;
        margin:0; padding:0;
    }
    a {
        font-weight:bold;
        text-decoration:none;
        &:focus,
        &:active,
        &:hover {
            text-decoration:underline;
        }
    }

    h3 a {
        text-decoration:underline;
        &:hover,
        &:focus {
            color:$primary;
        }
    }


    .planner > div {
        margin-top:1em;
    }

    .resource-intro {
        border-bottom:2px solid #ccc;
        margin-bottom:1em;
    }
}

.planner {
    padding:1em;
    padding-bottom:4em;

    max-width:80%;
    margin:0 auto;

    #loader {
        display:inline-block;
        label {
            cursor:pointer;

        }
    }


    .saver {
        margin:1em 0;
        text-align:center;
        .btn {
            display:inline-block;
            margin:0.25em;
            font-size:1em;
            cursor:pointer;

            &:last-child {
                margin:0;
            }

            &:hover,
            &:focus {
                @extend .btn-hover;
            }
        }

    }

    h2, h3 {
        color:$secondary;
    }

    h2 {
        font-size:1.25em;
    }

    .sections h3 {
        font-size:1.5em;
    }

    h3 {
        font-weight:700;
        margin:0;
        padding:0;

        small {
            display:block;
            color:#000;
            font-weight:normal;
            font-size:0.65em;
        }

        button {
            display:block;
            width:100%;
            background:inherit;
            text-align:inherit;
            font:inherit;
            border:inherit;
            color:inherit;
            cursor:pointer;
            padding:0.5em 0;
        }

        button:focus {
            outline:0;
            text-decoration:underline;
        }
    }

    h3 [aria-expanded] {
        position:relative;
        padding-right:1em;
    }

    h3 [aria-expanded]:before {
        @extend .icon;
        content: '\f13a';
        text-decoration:none;
        color:$primary;
        position:absolute;
        right:0;
        top:50%;
        transform: translateY(-50%);
    }

    h3 [aria-expanded="true"]:before {
        @extend .icon;
        content: '\f139';
    }

    h3 + [aria-hidden] {
        display: none;
    }

    h3 + [aria-hidden="false"] {
        display: block;
    }


    fieldset {
        margin-top:1em;

        border:0;
        border-bottom:1px solid #ccc;
        margin:1em 0 0 0;
        padding:0;
        padding-bottom:1em;

        fieldset{
            border:1px solid #ccc;
            padding:1em;
            position:relative;
        }

        .cust-ctrl:before {
            @extend .icon;
        }

        .cust-ctrl {
            font-size:1em;
            cursor:pointer;
        }

        button.remove {
            position:absolute;
            top:0; right:0;
            border:0;
            span {
                font-size: 0;
                height: 1px;
                overflow: hidden;
                display: block;
            }
        }

        button.remove:before {
            content:"\f05c";
        }

        button.add {
            display:block;
            margin:1em 0;
            width:100%;
        }

        button.add:before {
            padding-right:1em;
            content:"\f055";
        }

    }

    legend {
        @extend .serif;
        font-weight:bold;
        font-size:0.9em;
    }

    textarea,
    input[type="email"],
    input[type="tel"],
    input[type="url"],
    input[type="text"] {
        display:block;
        width:90%;
        border:1px solid $primary;
        background:#fff;
        color:#000;
        padding:0.75em 0.5em;
        margin-bottom:1em;
    }

    .checks li {border-bottom:1px solid #ccc; overflow:auto;}
    .checks li label {display:block; padding:0.25em 0}

    input[type="checkbox"] {
      border: 0;
      margin: 0;
      opacity: 0;
      outline: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
    }

    input[type="checkbox"] + label {
        display: block;
        padding-left: 1.5em;
        text-indent: -.7em;
    }

    input[type="checkbox"]:focus + label {
        color:$secondary;
    }

    input[type="checkbox"] + label:before {
        display: inline-block;
        font-family: "FontAwesome" !important;
        letter-spacing: 10px;
        cursor: pointer;
    }

    input[type="checkbox"] + label:before {
        content: "\f096";
    }

    input[type="checkbox"]:checked + label:before {
        content: "\f046";
    }

    textarea:focus,
    input[type="email"]:focus,
    input[type="url"]:focus,
    input[type="tel"]:focus,
    input[type="text"]:focus {
        border-width:2px;
    }

    input[readonly] {
        border-color:#ccc;
        background-color:#eee;
    }


    .tro {
        padding:0.5em 0;
    }
}


[dir="rtl"] .planner {
    input[type="checkbox"] + label {
        display: block;
        padding-right: 1.7em;
        padding-left:0;
        text-indent: -.7em;
    }


    fieldset button.remove {
        right:auto;
        left:0;
    }

    fieldset button.add:before {
        padding-right:0em;
        padding-left:1em;
    }
}

.planner .plan-section {
    border-bottom:1px solid #ccc;
}


.jswidget,
nav h2 {position: absolute; clip: rect(0,0,0,0);}




.reset, html,
body, .menu,
.step-list,
.checks {
  padding: 0;
  margin: 0;
}

.sans, body, .subhead {
  font-family: "source-sans-pro", sans-serif;
  font-weight: 400;
}

.header,
h1,
.serif,
.subheadxx,
.site-footer .content .byline div,
h2 strong,
.resources-page .planner h4,
.planner legend {
  text-transform: uppercase;
  font-family: "adelle-condensed", serif;
  font-weight: 200;
  letter-spacing: 1px;
}

h1 img {
  vertical-align: middle;
  max-width: 150px;
}

body {
  background: #f6fdfa;
  font-size: 1.25em;
}

.notice {
  text-align: center;
  border: 1px solid #00a88c;
  color: #00a88c;
  font-weight: bold;
  padding: 1em;
}

.btn.save, .plans .plan a.save {
  width: 100%;
}

.list-btn {
  margin: 0.5em;
}

a:link,
a:visited {
  color: #1f5aaf;
}

.icon, #lang-alert a strong:before, .masthead #select-lang:before, .planner h3 [aria-expanded]:before, .planner h3 [aria-expanded="true"]:before, .planner fieldset .cust-ctrl:before {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

#lang-alert {
  width: 100%;
  background: black;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 3px 2px #000;
  font-size: 0.8em;
}

#lang-alert a {
  text-decoration: none;
  display: block;
  padding: 0.15em;
}

#lang-alert a:link, #lang-alert a:visited {
  color: #fff;
}

#lang-alert a:hover, #lang-alert a:focus {
  background: #fff;
  color: #000;
}

#lang-alert a strong:before {
  content: "\f071";
  padding-right: 0.5em;
}

.header {
  text-align: center;
}

.subhead {
  display: block;
  font-weight: bold;
  text-transform: none;
  font-size: 0.5em;
  margin-top: 0.5em;
}

.subheadxx {
  font-size: 0.35em;
  max-width: 350px;
  margin: 2em auto 0 auto;
}

.masthead {
  background: #00a88c;
  color: #fff;
}

.masthead .brand,
.masthead .toggler {
  font-size: 1.5em;
  display: inline-block;
  font-weight: bold;
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead .brand,
  .masthead .toggler {
    font-size: 1em;
  }
}

.masthead .toggler {
  margin-left: 1em;
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead .toggler {
    display: none;
  }
}

.masthead a,
.masthead a:link,
.masthead a:focus,
.masthead a:hover,
.masthead a:active {
  outline: 0;
  text-decoration: none;
  color: #fff;
}

.masthead .toggler:focus,
.masthead .brand a:focus {
  color: #1f5aaf;
}

.masthead li {
  display: inline;
}

.masthead li a {
  display: block;
  padding: 0.5em 0;
  border: 1px solid #00dbb7;
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead li a {
    display: inline-block;
    padding: 0;
    border: 0;
    border-bottom: 2px solid transparent;
    margin-right: 1em;
  }
}

.masthead li.active a {
  background: #00c2a1;
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead li.active a {
    background: transparent;
    border-color: #00c2a1;
  }
}

.masthead li a:hover,
.masthead li a:focus,
.masthead li a:active {
  color: #00a88c;
  background: #fff;
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead li a:hover,
  .masthead li a:focus,
  .masthead li a:active {
    color: inherit;
    background: transparent;
  }
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead .brand a:hover,
  .masthead .brand a {
    border-bottom: 2px solid transparent;
  }
  .masthead .brand a:focus {
    color: #fff;
  }
  .masthead a:hover,
  .masthead a:focus,
  .masthead li a:hover,
  .masthead li a:focus {
    border-color: #fff;
  }
}

.masthead nav {
  display: block;
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead nav {
    display: inline-block;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 480px), screen and (max-height: 500px) {
  .masthead[data-nav-visible="false"] #navigation {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (min-height: 500px) {
  .masthead {
    width: 50%;
    position: fixed;
    top: 2em;
    left: 0;
    background: transparent;
  }
}

.masthead #select-lang span {
  display: none;
}

.masthead #lang-container ul {
  width: 100%;
}

@media only screen and (min-width: 480px) and (min-height: 500px) {
  .masthead #lang-container ul {
    position: absolute;
    left: 0;
    padding: 0.25em 0;
    text-align: center;
    background-color: #00a88c;
  }
}

@media only screen and (min-width: 992px) and (min-height: 500px) {
  .masthead #lang-container ul {
    background-color: transparent;
  }
}

.masthead #select-lang:before {
  content: '\f1ab';
  font-size: 1.6em;
  vertical-align: middle;
}

.masthead #lang-container[data-nav-visible="false"] ul {
  display: none !important;
}

.masthead #lang-container[data-nav-visible="true"] ul {
  display: block !important;
}

.menu,
.step-list,
.checks {
  list-style-type: none;
}

.step-list a {
  text-decoration: none;
}

.step-list a:hover, .step-list a:active, .step-list a:focus {
  text-decoration: underline;
}

.homehead {
  background: #191f2e url(/img/anim.gif) no-repeat bottom;
}

.pagehead {
  background-size: cover;
  color: #fff;
  padding: 2em 0;
  text-align: center;
  box-shadow: inset 0 225px 325px -125px black;
}

.pagehead h1 {
  margin: 0;
  line-height: 1.1;
  text-shadow: 0 0 0.25em rgba(0, 0, 0, 0.85);
}

@media only screen and (min-width: 992px) and (min-height: 500px) {
  .pagehead {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
  }
}

.about-page header.pagehead {
  box-shadow: none;
}

.about-page header.pagehead h1 {
  text-shadow: none;
}

@media only screen and (min-width: 992px) and (min-height: 500px) {
  .pagehead > div {
    font-size: 1.5em;
    width: 15em;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-60%);
  }
}

@media only screen and (min-width: 1200px) and (min-height: 500px) {
  .pagehead > div {
    font-size: 2em;
  }
}

@media only screen and (min-width: 992px) and (max-height: 850px) {
  .pagehead {
    font-size: 0.6em;
    padding-top: 8em;
  }
}

@media only screen and (min-width: 1450px) and (max-height: 850px) {
  .pagehead {
    font-size: 0.8em;
    padding-top: 7em;
  }
}

.site-footer,
main {
  display: block;
}

@media only screen and (min-width: 992px) and (min-height: 500px) {
  .site-footer,
  main {
    margin-left: 50%;
    padding: 1em 0 0 0;
  }
}

.site-footer {
  padding: 2em 0;
  padding-top: 4em;
  border-top: 3px solid #00a88c;
  background: #fff;
}

.site-footer .content {
  max-width: 90%;
  margin: 0 auto;
}

.site-footer .content small {
  display: block;
  font-size: 0.75em;
}

.site-footer .content .byline {
  text-align: center;
}

.site-footer .content .byline a {
  color: inherit;
}

.site-footer .content .byline a:hover, .site-footer .content .byline a:active, .site-footer .content .byline a:focus {
  text-decoration: none;
}

.site-footer .content .byline div {
  margin-bottom: 1em;
  font-size: 80%;
}

.site-footer .content .byline div * {
  margin-right: 1em;
}

.site-footer .content .byline img {
  display: inline-block;
  width: 25%;
  margin-right: 2em;
  vertical-align: middle;
}

.site-footer .content .logos {
  text-align: center;
  padding: 1em 0;
}

.site-footer .content .logos ul {
  list-style-type: none;
}

.site-footer .content .logos ul li {
  display: inline;
}

.site-footer .content .logos img {
  display: inline-block;
}

.site-footer .content .disclaim {
  text-align: center;
  max-width: 575px;
  margin: 0 auto;
}

.btn, .plans .plan a {
  font-weight: bold;
  color: #1f5aaf;
  background: transparent;
  display: inline-block;
  border: 1px solid;
  border-radius: 5px;
  text-decoration: none;
  padding: 0.15em 2em;
}

.btn-hover, .btn:hover, .plans .plan a:hover, input.has-focus + .btn, .plans .plan input.has-focus + a,
.plan:hover .btn, .plans
.plan:hover a,
.btn:active, .plans .plan a:active,
.btn:focus, .plans .plan a:focus, .planner .saver .btn:hover, .planner .saver .plans .plan a:hover, .plans .plan .planner .saver a:hover, .planner .saver .btn:focus, .planner .saver .plans .plan a:focus, .plans .plan .planner .saver a:focus {
  outline: 0;
  background-color: #1f5aaf;
  color: #fff;
  border-color: transparent;
}

.plans .plan {
  padding: 2.5em 1em;
}

.plans .plan:first-child {
  margin-top: -1em;
}

.plans .plan .plan-content {
  max-width: 75%;
  margin: 0 auto;
}

.plans .plan h1 {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #00a88c;
  font-size: 2em;
}

.plans .plan .plan-desc {
  margin: 1em 0;
}

.plans .plan:hover,
.plans .plan.focused {
  background: #00a88c;
  color: #fff;
}

.plans .plan:hover h1,
.plans .plan.focused h1 {
  color: #fff;
}

.plan .btn, .plans .plan a {
  cursor: pointer;
}

.planhead {
  font-size: 0.65em;
  background: #00a88c;
  text-align: left;
}

.planhead small {
  font-size: 0.7em;
}

[dir="rtl"] .planhead {
  text-align: right;
}

header.head-family {
  background: #00a88c url(/img/plan-bg/family.jpg) no-repeat center center;
  background-size: cover;
}

header.head-biz {
  background: #00a88c url(/img/plan-bg/business.jpg) no-repeat top right;
  background-size: cover;
}

header.head-faith {
  background: #00a88c url(/img/plan-bg/faith.jpg) no-repeat top right;
  background-size: cover;
}

.resources-page header {
  background: #00a88c url(/img/plan-bg/resources.jpg) no-repeat top right;
  background-size: cover;
}

.planhead > div {
  padding: 1em;
  margin-top: 1em;
  position: relative;
  z-index: 90100;
}

@media only screen and (min-width: 992px) and (min-height: 500px) {
  .planhead > div {
    max-width: 70%;
    margin: 0 auto;
  }
}

.alerts-sect {
  padding-left: 55px;
}

#local-signup img {
  float: left;
  border-radius: 50%;
}

#local-signup ul {
  list-style-type: none;
  margin: 0.5em 0 1em 0;
  padding: 0;
}

#local-signup ul li {
  margin-bottom: 0.125em;
}

[dir="rtl"] #local-signup img {
  float: right;
  margin-left: 10px;
}

[dir="rtl"] #local-signup ul {
  margin: 0.5em 3em 0 0;
}

h2 strong {
  display: block;
  font-size: 1.5em;
  color: #00a88c;
}

.priv {
  font-style: italic;
}

.priv strong {
  color: #1f5aaf;
}

#start {
  margin-top: 6em;
}

h3 {
  font-size: 1.25em;
}

.resources-page .planner h4 {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.resources-page a {
  font-weight: bold;
  text-decoration: none;
}

.resources-page a:focus, .resources-page a:active, .resources-page a:hover {
  text-decoration: underline;
}

.resources-page h3 a {
  text-decoration: underline;
}

.resources-page h3 a:hover, .resources-page h3 a:focus {
  color: #00a88c;
}

.resources-page .planner > div {
  margin-top: 1em;
}

.resources-page .resource-intro {
  border-bottom: 2px solid #ccc;
  margin-bottom: 1em;
}

.planner {
  padding: 1em;
  padding-bottom: 4em;
  max-width: 80%;
  margin: 0 auto;
}

.planner #loader {
  display: inline-block;
}

.planner #loader label {
  cursor: pointer;
}

.planner .saver {
  margin: 1em 0;
  text-align: center;
}

.planner .saver .btn, .planner .saver .plans .plan a, .plans .plan .planner .saver a {
  display: inline-block;
  margin: 0.25em;
  font-size: 1em;
  cursor: pointer;
}

.planner .saver .btn:last-child, .planner .saver .plans .plan a:last-child, .plans .plan .planner .saver a:last-child {
  margin: 0;
}

.planner h2, .planner h3 {
  color: #1f5aaf;
}

.planner h2 {
  font-size: 1.25em;
}

.planner .sections h3 {
  font-size: 1.5em;
}

.planner h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.planner h3 small {
  display: block;
  color: #000;
  font-weight: normal;
  font-size: 0.65em;
}

.planner h3 button {
  display: block;
  width: 100%;
  background: inherit;
  text-align: inherit;
  font: inherit;
  border: inherit;
  color: inherit;
  cursor: pointer;
  padding: 0.5em 0;
}

.planner h3 button:focus {
  outline: 0;
  text-decoration: underline;
}

.planner h3 [aria-expanded] {
  position: relative;
  padding-right: 1em;
}

.planner h3 [aria-expanded]:before {
  content: '\f13a';
  text-decoration: none;
  color: #00a88c;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.planner h3 [aria-expanded="true"]:before {
  content: '\f139';
}

.planner h3 + [aria-hidden] {
  display: none;
}

.planner h3 + [aria-hidden="false"] {
  display: block;
}

.planner fieldset {
  margin-top: 1em;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 1em 0 0 0;
  padding: 0;
  padding-bottom: 1em;
}

.planner fieldset fieldset {
  border: 1px solid #ccc;
  padding: 1em;
  position: relative;
}

.planner fieldset .cust-ctrl {
  font-size: 1em;
  cursor: pointer;
}

.planner fieldset button.remove {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
}

.planner fieldset button.remove span {
  font-size: 0;
  height: 1px;
  overflow: hidden;
  display: block;
}

.planner fieldset button.remove:before {
  content: "\f05c";
}

.planner fieldset button.add {
  display: block;
  margin: 1em 0;
  width: 100%;
}

.planner fieldset button.add:before {
  padding-right: 1em;
  content: "\f055";
}

.planner legend {
  font-weight: bold;
  font-size: 0.9em;
}

.planner textarea,
.planner input[type="email"],
.planner input[type="tel"],
.planner input[type="url"],
.planner input[type="text"] {
  display: block;
  width: 90%;
  border: 1px solid #00a88c;
  background: #fff;
  color: #000;
  padding: 0.75em 0.5em;
  margin-bottom: 1em;
}

.planner .checks li {
  border-bottom: 1px solid #ccc;
  overflow: auto;
}

.planner .checks li label {
  display: block;
  padding: 0.25em 0;
}

.planner input[type="checkbox"] {
  border: 0;
  margin: 0;
  opacity: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.planner input[type="checkbox"] + label {
  display: block;
  padding-left: 1.5em;
  text-indent: -.7em;
}

.planner input[type="checkbox"]:focus + label {
  color: #1f5aaf;
}

.planner input[type="checkbox"] + label:before {
  display: inline-block;
  font-family: "FontAwesome" !important;
  letter-spacing: 10px;
  cursor: pointer;
}

.planner input[type="checkbox"] + label:before {
  content: "\f096";
}

.planner input[type="checkbox"]:checked + label:before {
  content: "\f046";
}

.planner textarea:focus,
.planner input[type="email"]:focus,
.planner input[type="url"]:focus,
.planner input[type="tel"]:focus,
.planner input[type="text"]:focus {
  border-width: 2px;
}

.planner input[readonly] {
  border-color: #ccc;
  background-color: #eee;
}

.planner .tro {
  padding: 0.5em 0;
}

[dir="rtl"] .planner input[type="checkbox"] + label {
  display: block;
  padding-right: 1.7em;
  padding-left: 0;
  text-indent: -.7em;
}

[dir="rtl"] .planner fieldset button.remove {
  right: auto;
  left: 0;
}

[dir="rtl"] .planner fieldset button.add:before {
  padding-right: 0em;
  padding-left: 1em;
}

.planner .plan-section {
  border-bottom: 1px solid #ccc;
}

.jswidget,
nav h2 {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

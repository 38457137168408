// Here are some variables, then a mixin and then an application of the mixin - this will only compile using Sass 3.2 

//variables
// $XS: 12.5em; // 200px;
// $S: 18.75em; // 300px
// $SM: 35em; // 560px
// $M: 47.5em; // 760px
// $L: 63em; // 1008px
// $XL: 110em; // 1760px
// $XXL: 180em; // 2880px

$XS: 320px;
$S: 480px;
$SM: 768px;
$M: 992px;
$L: 1200px;
$XL: 1760px;
$XXL: 2880px;

$VERT:500px;

//mixin
@mixin MQ($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $S - 1) { @content; }
  }
  @else if $canvas == S {
    @media only screen and (min-width: $S) and (max-width: $M - 1) { @content; }
  }
  @else if $canvas == M {
    @media only screen and (min-width: $M) and (max-width: $L - 1) { @content; }
  }
  @else if $canvas == L {
    @media only screen and (min-width: $L) and (max-width: $XL - .01) { @content; }
  }
  @else if $canvas == XL {
    @media only screen and (min-width: $XL) and (max-width: $XXL - .01) { @content; }
  }
  @else if $canvas == XXL {
    @media only screen and (min-width: $XXL) { @content; }
  }
  @else if $canvas == XSplus {
    @media only screen and (min-width: $XS) { @content; }
  }
  @else if $canvas == Splus {
    @media only screen and (min-width: $S) and (min-height: $VERT) { @content; }
  }
  @else if $canvas == Mplus {
    @media only screen and (min-width: $M) and (min-height: $VERT) { @content; }
  }
  @else if $canvas == Lplus {
    @media only screen and (min-width: $L) and (min-height: $VERT) { @content; }
  }
  @else if $canvas == XLplus {
    @media only screen and (min-width: $XL) { @content; }
  }
  @else if $canvas == XXLplus {
    @media only screen and (min-width: $XXL) { @content; }
  }
  @else if $canvas == XSneg {
    @media only screen and (max-width: $XS) { @content; }
  }
  @else if $canvas == Sneg {
    @media only screen and (max-width: $S), screen and (max-height:$VERT) { @content; }
  }
  @else if $canvas == Mneg {
    @media only screen and (max-width: $M) { @content; }
  }
  @else if $canvas == Lneg {
    @media only screen and (max-width: $L) { @content; }
  }
  @else if $canvas == XLneg {
    @media only screen and (max-width: $XL) { @content; }
  }
  @else if $canvas == XXLneg {
    @media only screen and (max-width: $XXL) { @content; }
  }
  @else if $canvas == StoL {
    @media only screen and (min-width: $S) and (max-width: $L - .01) { @content; }
  }
}
